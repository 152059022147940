<!--
File: UnitEditForm.vue
Description: form for adding/editing a single measurement unit.
-->
<template>
  <modal v-if="eligible" :title="screenTitle" :form="formName" :errCount="errors.count()"
    :hasUnsavedChanges="hasUnsavedChanges" @close="$emit('close')" @save="validate">

    <template slot='body'>
      <md-field :class="getClass('description_en')">
        <label for="description_en">{{ $t('stdCols.name_en') }}</label>
        <md-input id="description_en" v-model="description_en" type="text" data-vv-name="description_en"
          v-validate="modelValidations.description_en" required />
      </md-field>

      <md-field :class="getClass('description_ru')">
        <label for="description_ru">{{ $t('stdCols.name_ru') }}</label>
        <md-input id="description_ru" v-model="description_ru" type="text" data-vv-name="description_ru"
          v-validate="modelValidations.description_ru" required />
      </md-field>

      <md-field :class="getClass('description_kg')">
        <label for="description_kg">{{ $t('stdCols.name_kg') }}</label>
        <md-input id="description_kg" v-model="description_kg" type="text" data-vv-name="description_kg"
          v-validate="modelValidations.description_kg" required />
      </md-field>
    </template>
  </modal>
</template>
<script>
  import { mapActions } from 'vuex'
  import { Modal } from '@/pages/Components'
  import permissions from "@/mixins/permissionsMixin"
  import messages from '@/mixins/messagesMixin'

  export default {
    name: 'unit-edit-form',
    mixins: [permissions, messages],

    data() {
      return {
        formName: 'UnitEditForm',
        eligible: false,

        description_en: null,
        description_ru: null,
        description_kg: null,

        initialFormState: null,

        modelValidations: {
          description_en: { required: true, min: 1 },
          description_ru: { required: true, min: 1 },
          description_kg: { required: true, min: 1 },
        }
      }
    },

    props: {
      itemId: null
    },

    components: {
      Modal,
    },

    async mounted() {
      // Check if we are eligible to view the form
      this.eligible = await this.checkIfScreenAllowed()
      if (!this.eligible) {
        this.$emit('close')
        return
      };

      if (this.itemId) {
        // Load data on existing treatment
        const unit = await this.loadUnit(this.itemId)
        this.description_en = unit.description_en
        this.description_ru = unit.description_ru
        this.description_kg = unit.description_kg
      }

      this.$nextTick(() => {
        this.saveInitialState()
        this.$validator.validateAll()
      })
    },

    methods: {
      ...mapActions({
        loadUnit: 'LOAD_UNIT_BY_ID',
        addUnit: 'ADD_NEW_UNIT',
        editUnit: 'UPDATE_UNIT',
        highlightRow: 'HIGHLIGHT_ROW'
      }),

      async validate() {
        const isValid = await this.$validator.validateAll()
        if (!isValid) return

        const theItem = {
          description_en: this.description_en,
          description_ru: this.description_ru,
          description_kg: this.description_kg
        }

        let errDesc = '';
        let newId;
        const action = !this.itemId ? this.addUnit : this.editUnit;
        const payload = !this.itemId ? theItem : { id: this.itemId, theItem };
        try {
          const res = await action(payload)
          newId = res?.id;
        } catch (err) {
          errDesc = err.message || this.$t('messages.unknown_error')
        }

        this.$nextTick(() => this.$validator.reset())
        this.$emit('close')
        await this.savedMessage(errDesc, this.$t('label.units'), this.description_ru, this.description_en)
        this.highlightRow(newId)
      },

      saveInitialState() {
        this.initialFormState = {
          description_en: this.description_en,
          description_ru: this.description_ru,
          description_kg: this.description_kg
        }
      },
    },

    computed: {
      screenTitle() {
        return this.itemId ? this.$t('screen_titles.unit_upd') : this.$t('screen_titles.unit_add')
      },

      hasUnsavedChanges() {
        if (!this.initialFormState) return false

        return this.description_en !== this.initialFormState.description_en ||
          this.description_ru !== this.initialFormState.description_ru ||
          this.description_kg !== this.initialFormState.description_kg
      }
    }
  }
</script>